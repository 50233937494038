
/* src/styles/tailwind.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';





@tailwind base;
@tailwind components;
@tailwind utilities;