:root {
	--main-color: #ec7211;
	--background-color: #fff4ec;
}
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@media print {
	.page-break {
		page-break-inside: always;
	}
	.no-print {
		display: none;
	}
}
.fc .fc-button-primary {
	background-color: var(--background-color);
	background: var(--main-color);
	color: white;
	border: none;
}

.fc-timeline-header-row {
	background: var(--main-color);
	font-weight: 400;
	color: white;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
	background-color: var(--background-color);
	border: none;
	color: var(--main-color);
}
.fc-col-header {
	background: var(--main-color);
	font-weight: 400;
	color: white;
}

.fc-toolbar-title {
	font-size: 25px !important;
}
@media only screen and (max-width: 600px) {
	.fc-toolbar-title {
		font-size: 0.75em !important;
	}
	.fc-toolbar-chunk .fc-button-group button {
		padding: 0.5em !important;
		font-size: small;
	}
}
